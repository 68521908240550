import React from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './Membership.module.scss';

const cx = classNames.bind(styles);

const Membership = () => (
  <ScreenClassRender
    render={screenClass => {
      const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

      return (
        <div className={cx('container')}>
          <Container>
            <Row>
              <Col xs={12} lg={10} offset={{ lg: 1 }}>
                <Row className={cx(['section', 'section01'])}>
                  <Col xs={12} lg={2.5}>
                    <div className={cx(['headNumber', { center: isMobile }])}>01</div>
                    <div className={cx(['headTitle', { center: isMobile }])}>
                      <span>클럽아티제</span>
                      <br /> 회원이 되려면?
                    </div>
                  </Col>
                  <Col xs={12} lg={9.5}>
                    <div className={cx('itemTitle')}>회원가입</div>
                    <div className={cx('itemDesc')}>아티제 홈페이지 또는 모바일 앱에서 회원가입을 합니다.</div>
                    <div className={cx('itemTitle')}>카드등록</div>
                    <div className={cx('itemDesc')}>
                      아티제 전 매장에서 발급 받은 충전식 선불카드를 홈페이지 또는 모바일 앱 로그인 후 등록합니다.
                      <br /> (모바일 앱에서 회원가입 시 카드가 생성되며, 매장에서 발급받은 카드를 제한없이 추가 등록할
                      수 있습니다.)
                    </div>
                    <div className={cx('itemTitle')}>포인트</div>
                    <div className={cx('itemDesc')}>
                      등록된 카드를 사용하실 때마다 포인트가 적립되며, 적립된 포인트는 현금과 같이 사용하실 수 있습니다.
                    </div>
                  </Col>
                </Row>

                <Row className={cx(['section', 'section02'])}>
                  <Col xs={12} lg={2.5}>
                    <div className={cx(['headNumber', { center: isMobile }])}>02</div>
                    <div className={cx(['headTitle', { center: isMobile }])}>
                      <span>클럽아티제</span>
                      <br /> 회원이 되시면?
                    </div>
                  </Col>
                  <Col xs={12} lg={4.75}>
                    <div className={cx(['box', { fixed: !isMobile }])}>
                      <div className={cx('title')}>Add extra</div>
                      <div className={cx('desc')}>
                        매장과 아티제 커넥트에서 클럽아티제 충전금 주문금액 전액 결제 시, 커피 음료에 한 해 add extra가
                        1회 무료 제공됩니다.
                      </div>
                      <div className={cx('descSmall')}>
                        (단, 결제수단 혼용 시 제외 /<br />
                        세트메뉴 구매 시 제외)
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={4.75}>
                    <div className={cx(['box', { fixed: !isMobile }])}>
                      <div className={cx('title')}>
                        MD products
                        <br /> 20% off
                      </div>
                      <div className={cx('desc')}>
                        클럽아티제 충전금액 또는 포인트 결제 시, 아티제 MD 상품 상시 20% 할인 받으실 수 있습니다.
                      </div>
                      <div className={cx('descSmall')}>
                        (충전금액과 포인트 혼용 가능 /<br />
                        일부상품 제외)
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className={cx(['section', 'section03'])}>
                  <Col xs={12} lg={2.5}>
                    <div className={cx(['headNumber', { center: isMobile }])}>03</div>
                    <div className={cx(['headTitle', { center: isMobile }])}>
                      <span>클럽아티제</span>
                      <br /> 등급별 혜택
                    </div>
                  </Col>
                  <Col xs={12} lg={9.5}>
                    <Row className={cx('boxWide')}>
                      <Col xs={12} lg={3}>
                        <div className={cx('title')}>Welcome</div>
                      </Col>
                      <Col xs={12} lg={9} className={cx('desc')}>
                        <ul>
                          <li>구매 시 마다 최종 결제금액의 3% 적립</li>
                          <li>최근 12개월 누적 적립 포인트가 9,000P 이상 시, Friend로 승급</li>
                        </ul>
                      </Col>
                    </Row>
                    <Row className={cx('boxWide')}>
                      <Col xs={12} lg={3}>
                        <div className={cx('title')}>Friend</div>
                      </Col>
                      <Col xs={12} lg={9} className={cx('desc')}>
                        <ul>
                          <li>생일 무료음료 쿠폰 (연 1회)</li>
                          <li>제조음료 1+1 쿠폰 (이벤트 시)</li>
                          <li>조각 케이크 무료 쿠폰 1매 (승급 시)</li>
                          <li>프로모션 진행 시 다양한 혜택 제공</li>
                          <li>구매 시 마다 최종 결제금액의 3% 적립</li>
                          <li>최근 12개월 누적 적립 포인트가 40,000P 이상 시, 아티지엔으로 승급</li>
                        </ul>
                      </Col>
                    </Row>
                    <Row className={cx('boxWide')}>
                      <Col xs={12} lg={3}>
                        <div className={cx('title')}>Artisienne</div>
                      </Col>
                      <Col xs={12} lg={9} className={cx('desc')}>
                        <ul>
                          <li>생일 홀케이크 쿠폰 (연 1회 / 스페셜 케이크 제외)</li>
                          <li>커피 1+1 쿠폰 (연 4회 : 1, 4, 7, 10월)</li>
                          <li>시즌음료 1+1 쿠폰 (수시)</li>
                          <li>
                            아티지엔 승급 축하 쿠폰
                            <br /> 아티지엔 테디베어 키링 & 아티지엔 카드 (회원 ID 당 1회)
                          </li>
                          <li>프로모션 진행 시 다양한 혜택 제공</li>
                          <li>구매 시 마다 최종 결제금액의 5% 적립</li>
                          <li>최근 12개월 누적 적립 포인트가 50,000P 이상 시, 등급 유지</li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className={cx(['section', 'section04'])}>
                  <Col xs={12} lg={2.5}>
                    <div className={cx(['headNumber', { center: isMobile }])}>04</div>
                    <div className={cx(['headTitle', { center: isMobile }])}>
                      <span>클럽아티제</span>
                      <br /> 충전식 선불카드
                      <br /> 사용방법
                    </div>
                  </Col>
                  <Col xs={12} lg={9.5}>
                    <div className={cx('itemTitle')}>충전방법</div>
                    <div className={cx('itemDesc')}>매장, 모바일 APP에서 충전 가능합니다.</div>
                    <div className={cx('itemTitle')}>충전금액</div>
                    <div className={cx('itemDesc')}>
                      카드 충전은 아티제 충전가능 매장 또는 모바일앱에서 가능하며, 매장의 경우 만원 단위로 1만원부터
                      50만원까지 충전이 가능합니다. 1회 최대 충전가능 금액은 10만원입니다.
                    </div>
                    <div className={cx('itemTitle')}>결제수단</div>
                    <div className={cx('itemDesc')}>신용카드, 현금, 휴대폰 소액결제(모바일앱)로 가능합니다.</div>
                    <div className={cx('itemTitle')}>유효기간</div>
                    <div className={cx('itemDesc')}>최종 충전일로부터 5년입니다.</div>
                    <div className={cx('itemTitle')}>잔액환불</div>
                    <div className={cx('itemDesc')}>최종 충전 금액의 40% 이하인 경우 매장에서 가능합니다.</div>
                    <div className={cx('itemTitle')}>문의전화</div>
                    <div className={cx('itemDesc')}>02-2155-5777 (보나비 CS센터)</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }}
  />
);

export default Membership;
